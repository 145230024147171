#calendar {
    padding: 4rem 0;
    background: var(--bg-beige);
}

#calendar h2 {
    margin-top: var(--top-margin);
}

.calendar__events {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
    gap: 1.2rem;
}

.calendar__event {
    border-radius: var(--border-radius);
}

.calendar__event-info p,
.calendar__event-info a {
    font-size: .8rem;
}

.calendar__event-image img {
    width: 100%;
    object-fit: cover;
}

.calendar__event-image {
	position: relative;
}

.calendar__date {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
}

.calendar__day {
	font-size: 24px; 
	font-weight: bold;
}

.calendar__month {
	font-size: 16px;
}

.calendar__event h3 {
    font-family: 'ChampagneLimousines';
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    margin-top: .5rem;
}

.calendar__event p {
    text-align: center;
}

.recents {
    opacity: .7;
}

@media screen and (max-width: 768px) {
    .calendar__events {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    .calendar__event-image img {
        width: 100%;
    }
}

/* NEW CALENDAR */
.calendar-wrap {
    padding-top: 5rem;
    margin: 0 -.5rem;
}

.fc-header-toolbar {
    margin-bottom: 0 !important;
}

.fc-daygrid-day-number {
    color: #1a1a1a;
    text-decoration: none;
}

.fc .fc-col-header-cell-cushion {
    color: #333;
    text-decoration: none;
    font-weight: 300;
    font-size: .8rem;
}

.fc .fc-daygrid-day {
    padding: .5rem;
}

.fc .fc-scrollgrid-section-sticky > * {
    background: var(--bg-beige);
}

.fc-daygrid-day-frame {
    min-height: 9rem !important;
    height: 100%;
    font-family: 'ChampagneLimousines' !important;
    color: #000;
}

.event__wrap {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    justify-content: center;
}

.event__wrap:hover {
    /* cursor: pointer; */
    transform: scale(1.05);
}

.event__icon {
    margin: 0 auto;
    padding: .2rem;
}

.event__title {
    font-family: 'ChampagneLimousines';
    font-size: 1rem;
    font-weight: 600 !important;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word; 
    text-align: center;
    padding: 0 4px; 
    line-height: 1.2;
}

.fc-button {
    background: transparent !important;
    color: var(--primary-color) !important;
    border: none !important;
    margin-bottom: .5rem !important;
}

.fc-button:focus {
    border: none !important;
    box-shadow: none !important;
}

.book-table-button {
    font-size: .8rem !important;
    font-family: 'ChampagneLimousines' !important;
    font-weight: 700 !important;
}

.fc-list-day-cushion a {
    font-size: 1rem;
    color: var(--text-black);
    font-weight: 500;
    text-decoration: none;

}


@media screen and (max-width: 1024px) {
    .fc-col-header-cell-cushion {
        font-size: 0.8rem;
    }

    .fc-daygrid-day-frame {
        min-height: 5rem !important;
    }

    .fc-daygrid-day {
        padding: .1rem !important;
        overflow: hidden !important;
        flex-wrap: wrap;
        font-size: .7rem !important;
    }

    .event__wrap {
        align-items: flex-start;
    }
}