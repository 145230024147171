.copy {
    font-size: .9rem;
}

footer h5 {
    text-transform: uppercase;
}


footer p,
footer a {
    /* font-family: 'OptimaLTStd' !important; */
    font-size: 1rem;
}

footer a {
    color: inherit;
    color: var(--primary-color);
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

.champ-title {
    padding-top: 3rem;
    font-family: 'JennaSue';
    font-size: 2rem !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    color: #0e5e1f !important;
    margin-block-end: 0rem;
}