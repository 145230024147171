.about {
    min-height: 95vh;
}

.about p {
    font-size: 1.1rem !important;
}

.about-image {
    margin-top: 5rem;
    max-width: 100%;
}