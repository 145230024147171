.event {
    min-height: 95vh;
}

.event-image-1 {
    margin-top: 7rem;
    max-width: min(40%, 10rem);
    float: right;
    margin-right: 3rem;
}

.event-image-2 {
    width: min(100%, 20rem);
    margin-top: 13rem;
    margin-left: 2rem;
}

@media screen and (max-width: 991px) {
    .event-image-1 {
        margin-top: 1rem;
        height: 10rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        float: none;
    }

    .event-image-2 {
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}