html {
  background: #fbf7f4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --border-radius: .5rem;
  --primary-color: #0e5e1f;
  --bg-beige: #fbf7f4;
  --default-font: 'ChampagneLimousines', sans-serif;
  --text-black: #030303;
}

.champ-title {
  font-family: 'ChampagneLimousines';
  font-size: 1.4rem;
  font-weight: 700;
}

.title {
  font-family: 'JennaSue';
  font-size: 2rem;
  color: var(--primary-color);
  padding-top: 1rem;
  font-weight: 700;
}


.beige-bg {
	background: #fbf7f4 !important;
}

.white-bg {
	background: #fff !important;
}

.text-green {
  color: #0e5e1f !important;
}

label {
  font-family: 'ChampagneLimousines', sans-serif;
  font-weight: 700;
}

input, textarea {
  font-family: 'ChampagneLimousines', sans-serif !important;
  background: transparent !important;
  border: 1px solid #d7ae5e !important;
  font-size: .9rem;
}

input:active,
input:focus,
textarea:active,
textarea:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 165, 32, 0.25) !important;
}

section {
  min-height: 95vh;
}

.box-3d {
  box-shadow: 
    0px 1px 2px rgba(0,0,0,0.03), 
    0px 2px 4px rgba(0,0,0,0.03), 
    0px 4px 8px rgba(0,0,0,0.03), 
    0px 8px 16px rgba(0,0,0,0.03),
    0px 16px 32px rgba(0,0,0,0.03),
    0px 32px 64px rgba(0,0,0,0.03) !important;
}

/* ANIMATIONS */
.fly-left {
  animation: flyInFromLeft .5s ease-in-out;
}

.fly-right {
  animation: flyInFromRight .5s ease-in-out;
}

.fly-bottom {
  animation: flyInFromBotton .5s ease-in-out;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

/* ANIMS */
@keyframes flyInFromRight {
  from {
      transform: translateX(20%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes flyInFromLeft {
  from {
      transform: translateX(-20%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes flyInFromBotton {
  from {
      transform: translateY(20%);
  }
  to {
      transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes moveUpDown {
0%, 100% {
  transform: translateY(-.5rem);
}
50% {
  transform: translateY(.5rem);
}
}

