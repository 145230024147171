.team {
    min-height: 50vh;
}

.member {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}

.member img {
    border-radius: .5rem;
}

.member .name {
    font-size: 1.2rem;
    margin-top: .5rem;
}

.member-row {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 5rem;
}

/* Media Query for Mobile */
@media only screen and (max-width: 1024px) {
	.member-row {
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}
}