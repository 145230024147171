.mail-form-wrap {
    max-width: 600px;
    margin: 0 auto;
}

.mail-form-wrap form {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.button-group {
    min-width: 7rem;
}

.mail-form-wrap h1 {
    color: var(--primary-color);
}

@media screen and (max-width: 768px) {
    .mail-form-wrap {
        max-width: 100%;
    }

    .mail-form-wrap form {
        flex-direction: column;
        gap: 1rem;
    }

    .mail-form-wrap input {
        padding: 1rem 1rem !important;
    }

    .button-group {
        min-width: 100%;
    }
}