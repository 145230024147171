.default-button {
    padding: .5rem 2rem !important;
    border-radius: .5rem !important;
}

.btn {
    transition: all .1s ease-in-out !important;
    font-family: 'JennaSue' !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
}

.btn:hover {
    transform: translateY(-.1rem);
}

.btn-primary {
    background-color: transparent !important;
    color: #d7ae5e !important;
    border-color: #d7ae5e !important;
}

.btn-primary:hover {
    box-shadow: 0 0 0 0.2rem rgba(218, 165, 32, 0.25);
}

.btn-primary:active,
.btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(218, 165, 32, 0.25);
}

.btn-light {
    color: #173a6f !important;
    font-weight: 700 !important;
}

.button-icon {
    margin-right: .3rem;
    margin-bottom: .1rem;
}