/* Champagne & Limousines Fonts */
@font-face {
	font-family: 'ChampagneLimousines';
	src: url('./fonts/Champagne & Limousines.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ChampagneLimousines';
	src: url('./fonts/Champagne & Limousines Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'ChampagneLimousines';
	src: url('./fonts/Champagne & Limousines Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ChampagneLimousines';
	src: url('./fonts/Champagne & Limousines Bold Italic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

/* Jenna Sue Font */
@font-face {
	font-family: 'JennaSue';
	src: url('./fonts/JennaSue.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'OptimaLTStd';
	src: url('./fonts/OptimaLTStd.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

p {
	font-family: 'ChampagneLimousines', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'JennaSue', cursive;
}