.julbord p {
    font-size: 1.1rem;
}

.julbord__image {
    border-radius: .5rem;
    width: 100%;
    max-width: 30rem;
}

.julbord__subtitle {
    font-family: var(--default-font);
    font-size: 1.1rem;
    font-weight: 700;
}