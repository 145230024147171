.landing-wrap {
    background: #fbf7f4;
    min-height: auto !important;
}

.landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing img {
    object-fit: contain;
    margin-top: 4rem;
    /* width: 100%; */
    display: inline-block;
    width: max(50%, 30rem);
}

.landing Button {
    margin-top: 2rem; 
    margin-bottom: 7rem;
}