.nav-item {
	padding: 0rem 1rem !important;
}

.nav-link {
	font-family: 'JennaSue';
	font-size: 1.7rem !important;
	color: #0e5e1f !important;
	position: relative;
}

/* Base style for pill-shaped form */
.nav-link::after {
	content: "";
	display: block;
	width: 0; /* Initially hidden */
	height: 5px;
	background-color: rgba(14, 94, 31, 1);
	position: absolute;
	bottom: .5rem;
	left: 0;
	border-radius: .5rem;
	transition: width .3s ease; /* Add transition only for width */
}

/* For hover */
.nav-link:hover::after {
	width: 100%;
	background-color: rgba(14, 94, 31, .5);
}

/* For active and focus state, no transition */
.active .nav-link::after,
.nav-link:active::after,
.nav-link:focus::after {
	width: 100%;
	background-color: rgba(14, 94, 31, 1);
}

.bar {
    left: 0px;
    border-radius: 5pt;
    display: block;
    position: relative;
    transform-origin: right;
    height: 3pt !important;
    width: 28pt;
    background-color: rgba(14, 94, 31, 1);
    transition: all 0.3s ease;
}

.burger-box{
    width: 30pt;
    height: 28pt;
    padding-top: 5pt;
    margin-left: auto;
}

.burger-box:hover {
    cursor: pointer;
}

.bar1{
    top: 0pt;
}

.bar2{
    top: 8pt;
}

.bar3{
    top: 16pt;
}

.bar1, .bar3{
    transform-origin: right;
}

:root{
    --top-offset-after-animation: 1.29pt;
    --left-offset-after-animation: -4.5pt;
}

.active.bar1 {
	transform: rotate(-45deg);
	top: var(--top-offset-after-animation);
    left: var(--left-offset-after-animation);
}

.active.bar2 {
	opacity: 0%;
}

.active.bar3 {
	transform: rotate(45deg);
	top: calc(16pt - var(--top-offset-after-animation));
    left: var(--left-offset-after-animation);
}

@media screen and (max-width: 991px) {
	.navbar-nav {
		height: 100vh;
		display: flex;
		padding-top: 5rem;
	}
}

@media screen and (min-width: 992px) {
	.burger-box {
		display: none;
	}
}